export const boostingTypography = {
  text1: {
    fontWeight: 700,
    fontSize: 64,
    lineHeight: 80 / 64
  },
  text2: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 36 / 24
  },
  text3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16
  },
  text4: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 24 / 18
  },
  text1Mobile: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: 48 / 36 /* 133.333% */
  },
  text2Mobile: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 32 / 20
  },
  text4Mobile: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 24 / 16
  }
};
