import {useRouter} from 'next/router';
import Head from 'next/head';
import clsx from 'clsx';
import {NextSeo} from 'next-seo';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import dynamic from 'next/dynamic';
// import PropTypes from 'prop-types';

import {PageContext} from 'src/context';
import {changeZopimPage} from 'src/core/zopim';
import HeaderNext from 'src/views/HeaderNext';
import {PageSlugs} from 'src/core/urls';
import useStorefrontData from 'src/hooks/useStorefrontData';

import {BreadcrumbSchema, FaqSchema, SchemaOrg} from './ShemaOrg';
// import {Snowflakes} from '../Snowflakes';

import Loader from 'src/components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
    // backgroundColor: theme.palette?.backgrounds?.dark
    // zIndex: -1
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette?.backgrounds?.dark,
    zIndex: -1
  }
}));

const defaultPageSeo = {
  title: 'Probemas',
  seoTitle: 'Probemas',
  seoDescription: 'Probemas',
  seoImage: {
    // eslint-disable-next-line no-undef
    url: `${process.env.ORIGIN}/images/probemas_og_image.jpg`
  },
  slug: '/'
};

const Footer = dynamic(() => import('../../views/FooterNext'), {
  loading: () => <Loader />
});

const NotIndexingPages = [
  PageSlugs.ACCOUNT_LOYALTY,
  PageSlugs.ACCOUNT_SETTINGS,
  PageSlugs.ACCOUNT_ORDERS,
  PageSlugs.ACCOUNT_REWARDS,
  PageSlugs.CHECKOUT,
  PageSlugs.DEMO,
  // PageSlugs.CUSTOM_PAYMENT,
  PageSlugs.ACCOUNT_ACTIVATE,
  PageSlugs.PASSWORD_RESET
];

export const PageNext = ({
  schemaOrg,
  faqSchema,
  breadcrumbsSchema,
  reviews,
  className,
  children,
  noBackground = false,
  isOldPage,
  isNewPage
}) => {
  const classes = useStyles();
  const router = useRouter();
  const pageUrl = router.asPath.split('?')[0].substring(1);
  const {page, shop} = useStorefrontData();

  if (shop) {
    defaultPageSeo.title = shop.name;
    defaultPageSeo.seoTitle = shop.name;
    defaultPageSeo.seoDescription = shop.description;
  }

  const pageSeo = page || defaultPageSeo;
  const desktopView = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const tabletView = useMediaQuery(theme =>
    theme.breakpoints.between('sm', 'md')
  );
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'));

  // trigger zopim page change event
  // eslint-disable-next-line no-undef
  changeZopimPage(`${process.env.ORIGIN}/${pageUrl}`);
  return (
    <>
      <Head>
        <title>{pageSeo.seoTitle}</title>
        <meta name='application-name' content='Probemas' />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicons/favicon-16x16.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicons/favicon-32x32.png'
        />
        <meta name='probemas-config' content='/favicons/browserconfig.xml' />
      </Head>
      <NextSeo
        title={pageSeo.seoTitle}
        description={pageSeo.seoDescription}
        /* eslint-disable-next-line no-undef */
        canonical={`${process.env.ORIGIN}/${pageUrl}`}
        noindex={NotIndexingPages.includes(pageUrl)}
        nofollow={NotIndexingPages.includes(pageUrl)}
        openGraph={{
          // eslint-disable-next-line no-undef
          url: `${process.env.ORIGIN}/${pageUrl}`,
          title: pageSeo.seoTitle,
          description: pageSeo.seoDescription,
          images: [{url: pageSeo.seoImage.url}],
          type: 'website',
          site_name: 'Probemas'
        }}
      />
      {schemaOrg && <SchemaOrg schema={schemaOrg} reviews={reviews} />}
      {faqSchema && page.faqItems && <FaqSchema items={page.faqItems.edges} />}
      {breadcrumbsSchema && <BreadcrumbSchema page={page} />}
      <PageContext.Provider
        value={{
          page,
          isDesktopView: desktopView,
          isTabletView: tabletView,
          isMobileView: mobileView
        }}
      >
        <HeaderNext isOldPage={isOldPage} isNewPage={isNewPage} />
        <div
          id='content'
          className={clsx(!noBackground && classes.root, className)}
        >
          <div className={classes.bg} />
          {/*<Snowflakes />*/}
          {children}
        </div>

        <Footer />
      </PageContext.Provider>
    </>
  );
};

PageNext.propTypes = {};
