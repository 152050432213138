import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {range} from 'lodash/util';
import Link from '@material-ui/core/Link';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';

import {TrustpilotLogoIcon} from 'src/componentsNext/icons/trustpilot/TrustpilotLogoIcon';
import {boostingTypography} from 'src/views/BoostingPageNext/boostingTypography';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {StarSimpleIcon} from './icons/reviews/StarSimpleIcon';

export const useStyles = makeStyles(theme => ({
  stars: {
    display: 'grid',
    gap: theme.spacing(0.5),
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    color: theme.palette.colors.yellow[900],

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(0.35)
    }
  },

  startsMobile: {
    gap: theme.spacing(0.35)
  },
  reviewsRow: {
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2),
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'rgba(56, 68, 94, 0.25)',
    border: `1px solid ${theme.palette.colors.blue[500]}`,
    borderRadius: 8,
    backdropFilter: 'blur(6px)',

    '& .stars svg': {
      color: theme.palette.colors.yellow[900]
    },

    '& > svg': {
      color: theme.palette.colors.yellow[900]
    },

    '&:hover': {
      opacity: 1,

      '& .stars svg': {
        color: theme.palette.colors.yellow[900]
      },

      '& > svg': {
        color: theme.palette.colors.yellow[900]
      }
    },

    '&:active, &:focus, &.Mui-focusVisible': {
      opacity: 1,

      '& > svg': {
        color: theme.palette.colors.yellow[900]
      }
    },

    [theme.breakpoints.down('sm')]: {
      gridAutoFlow: 'row',
      gridAutoColumns: '1fr',
      justifyItems: 'center'
    }
  },
  text: {
    ...extendedTypography.subtitleSemiboldDesktop,
    color: theme.palette.common.white,

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.body1MediumMobile
    }
  },
  textMobile: {...extendedTypography.body1MediumMobile},
  divider: {
    width: 0.5,
    height: 'calc(100% - 8px)',
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.colors.blue[500]
  },
  logoIcon: {
    width: 109,
    height: 24,

    [theme.breakpoints.down('sm')]: {
      width: 128,
      height: 28
    }
  },
  mobileRoot: {
    width: '100%'
  },
  topRowMobile: {
    display: 'flex',
    gap: theme.spacing(1.5)
  },
  topTextMobile: {
    color: theme.palette.common.white,
    ...extendedTypography.subtitleSemiboldDesktop
  },
  textCheckout: {
    color: theme.palette.common.white,
    ...extendedTypography.h5SemiboldDesktop
  },
  logoIconCheckout: {
    width: 146,
    height: 32,

    [theme.breakpoints.down('sm')]: {
      width: 128,
      height: 28
    }
  },
  reviewsRowCheckout: {
    justifyContent: 'space-between',
    border: 'none',
    padding: theme.spacing(2, 4),
    borderRadius: 4,

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export const ReviewsRowNext = ({className = '', isCheckout}) => {
  const classes = useStyles();

  // Using HiddenCss approach instead of media query
  // to avoid flickering on first page load

  if (isCheckout) {
    return (
      <>
        <HiddenCss mdUp className={classes.mobileRoot}>
          <Link
            href='https://www.reviews.io/company-reviews/store/probemas-com'
            target='_blank'
            rel='noopener noreferrer'
            className={clsx(
              classes.reviewsRow,
              className,
              isCheckout && classes.reviewsRowCheckout
            )}
          >
            <div className={classes.topRowMobile}>
              <Typography className={classes.topTextMobile}>
                Excellent
              </Typography>

              <div
                className={clsx(classes.stars, classes.starsMobile, 'stars')}
              >
                {range(0, 5).map(index => (
                  <StarSimpleIcon key={index} width={24} height={24} />
                ))}
              </div>
            </div>

            <Typography classes={{root: classes.text}}>
              Backed by 15,789 Player Reviews
            </Typography>

            <TrustpilotLogoIcon className={classes.logoIconCheckout} />
          </Link>
        </HiddenCss>
        <HiddenCss smDown>
          <Link
            href='https://www.reviews.io/company-reviews/store/probemas-com'
            target='_blank'
            rel='noopener noreferrer'
            className={clsx(
              classes.reviewsRow,
              className,
              isCheckout && classes.reviewsRowCheckout
            )}
          >
            <div className={clsx(classes.stars, 'stars')}>
              {range(0, 5).map(index => (
                <StarSimpleIcon key={index} width={24} height={24} />
              ))}
            </div>
            <div className={classes.divider} />
            <Typography classes={{root: classes.text}}>
              Trusted by 100,000+ OSRS Players Since 2013
            </Typography>
            <div className={classes.divider} />
            <TrustpilotLogoIcon className={classes.logoIconCheckout} />
          </Link>
        </HiddenCss>
      </>
    );
  }

  return (
    <>
      <HiddenCss mdUp className={classes.mobileRoot}>
        <Link
          href='https://www.reviews.io/company-reviews/store/probemas-com'
          target='_blank'
          rel='noopener noreferrer'
          className={clsx(classes.reviewsRow, className)}
        >
          <div className={classes.topRowMobile}>
            {/*<Typography className={classes.topTextMobile}>Excellent</Typography>*/}

            <div className={clsx(classes.stars, classes.starsMobile, 'stars')}>
              {range(0, 5).map(index => (
                <StarSimpleIcon key={index} width={24} height={24} />
              ))}
            </div>
          </div>

          <Typography classes={{root: clsx(classes.text, classes.textMobile)}}>
            Backed by 15,789 Player Reviews
          </Typography>

          <TrustpilotLogoIcon className={classes.logoIcon} />
        </Link>
      </HiddenCss>
      <HiddenCss smDown>
        <Link
          href='https://www.reviews.io/company-reviews/store/probemas-com'
          target='_blank'
          rel='noopener noreferrer'
          className={clsx(classes.reviewsRow, className)}
        >
          <TrustpilotLogoIcon className={classes.logoIcon} />

          <div className={classes.divider} />

          <Typography classes={{root: classes.text}}>
            Backed by 15,789 Player Reviews
          </Typography>

          <div className={clsx(classes.stars, 'stars')}>
            {range(0, 5).map(index => (
              <StarSimpleIcon key={index} width={24} height={24} />
            ))}
          </div>
        </Link>
      </HiddenCss>
    </>
  );
};

ReviewsRowNext.propTypes = {
  className: PropTypes.string
};
